/*--------------------------------------------------------------------------------------------
*
*	Vars
*
*--------------------------------------------------------------------------------------------*/

/* colors */
$acf_blue: #2a9bd9;
$acf_notice: #2a9bd9;
$acf_error: #d94f4f;
$acf_success: #49ad52;
$acf_warning: #fd8d3b;

/* acf-field */
$field_padding: 15px 12px;
$field_padding_x: 12px;
$field_padding_y: 15px;
$fp: 15px 12px;
$fy: 15px;
$fx: 12px;

/* responsive */
$md: 880px;
$sm: 640px;

// Admin.
$wp-card-border: #ccd0d4;			// Card border.
$wp-card-border-1: #d5d9dd;			// Card inner border 1: Structural (darker).
$wp-card-border-2: #eeeeee;			// Card inner border 2: Fields (lighter).
$wp-input-border: #7e8993;			// Input border.

// Admin 3.8
$wp38-card-border: #E5E5E5;			// Card border.
$wp38-card-border-1: #dfdfdf;		// Card inner border 1: Structural (darker).
$wp38-card-border-2: #eeeeee;		// Card inner border 2: Fields (lighter).
$wp38-input-border: #dddddd;		// Input border.